<template>
  <div
    id="app"
    class="flex h-dvh flex-col"
    :class="{
      '': visible,
      relative: !visible,
      color: currentColor
    }"
  >
    <navigation :color="currentColor" />
    <router-view :color="currentColor" class="flex-grow" />
    <footer-nav :color="currentColor" />
    <transition
      enter-active-class="fadeInModal"
      leave-active-class="fadeOutModal"
      mode="out-in"
    >
      <div class="z-50" v-if="visible">
        <modal :redirect="modalRedirect">
          <component
            :is="component"
            :data="modalData"
            :redirect="modalRedirect"
          />
        </modal>
      </div>
    </transition>
  </div>
</template>
<script>
import http from '@/http';
import store from '@/store';
import Navigation from '@/components/Navigation.vue';
import FooterNav from '@/components/Footer.vue';
import Modal from '@/components/modal/Modal';
import { mapGetters } from 'vuex';

import utils from '@/utils';

export default {
  name: 'core',
  components: {
    Navigation,
    FooterNav,
    Modal
  },
  metaInfo: {
    title: 'The Cool Cat Club',
    titleTemplate: '%s'
  },
  data() {
    return {
      component: null,
      utils,
      colors: utils.colorChanger(),
      colorCounter: 0,
      // colorCounter: -1,
      currentColor: utils.colorChanger()[0]
    };
  },
  computed: {
    ...mapGetters({
      visible: 'modalVisibleGetter',
      modalComponent: 'modalComponentGetter',
      modalData: 'modalDataGetter',
      modalRedirect: 'modalRedirectGetter'
    }),

    loader() {
      return () => import(`@/components/modal/dialogs/${this.modalComponent}`);
    }
  },
  watch: {
    modalComponent: function (componentName) {
      if (!componentName) return;
      this.loader().then(() => {
        this.component = () => this.loader();
      });
    },
    $route(to) {
      if (to.name != 'home') {
        clearInterval(this.interval);
      } else {
        this.startInterval();
      }
    },
    visible: function (val) {
      if (val) {
        let scrollTop = document.documentElement.scrollTop;
        document.documentElement.scrollTop = scrollTop;
        document.documentElement.style.overflow = 'hidden';
      } else {
        document.documentElement.style.overflow = 'auto';
      }
    }
  },
  created: function () {
    if (this.$store.getters.isLoggedIn) {
      this.$store.dispatch('getUser', true);
    } else {
      this.$store.commit('clear_storage', ['cats', 'plans', 'products']);
    }
    this.$store.dispatch('getProductGroups');
    this.$store.dispatch('getProducts');
    http.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          store.dispatch('logout');
        }
        throw err;
      });
    });
    this.startInterval();
  },
  methods: {
    startInterval: function () {
      let self = this;
      self.currentColor = self.colors[self.colorCounter];
      self.$store.commit('set_current_color', self.currentColor);
    }
  },

  mounted() {
    this.$store.commit('set_mp_id', this.utils.getMpId());
    if (localStorage.getItem('show_tg') === null) {
      localStorage.setItem('show_tg', true);
    }

    if (this.$route.query.r) {
      localStorage.setItem('referral', this.$route.query.r);

      this.$store.dispatch('sendMpEvent', {
        event: 'From referral',
        properties: {
          location: this.$route.name,
          referralCode: this.$route.query.r,
          type: 'EVT'
        }
      });
    }
    if (process.env.NODE_ENV != 'production') {
      if (window.Beacon) {
        window.Beacon('destroy');
      }
    }

    this.$store.dispatch('getTasterPrice');
    this.$store.dispatch('getDiscountAmount');
    this.$store.dispatch('getCorePrices', [30, 60, 90, 120]);
    this.$store.dispatch('getDeliveryOptions', {
      params: { type: 'subscription' }
    });
  }
};
</script>
